/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

@mixin desktop {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px)
        and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px)
        and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin ultra-wide-desktop {
    @media (min-width: 320px)
        and (max-width: 1440px) {
        @content;
    }
}

@mixin double-ultra-wide-desktop {
    @media (min-width: 768px)
        and (max-width: 1440px) {
        @content;
    }
}

@mixin narrow-tablet {
    @media (min-width: 768px)
        and (max-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px)
        and (max-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: landscape) {
        @content;
    }
}

@mixin ultra-narrow-mobile {
    @media (max-width: 360px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 810px) {
        @content;
    }
}

@mixin mobile-height-small {
    @media (max-height: 667px) {
        @content;
    }
}

@mixin narrow-mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mini-mobile {
    @media (max-width: 380px) {
        @content;
    }
}

@mixin mini-mobile-2 {
    @media (min-width: 381px) and (max-width: 480px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}
