/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CartItem {
    border-bottom: none;

    &-Link {
        align-self: center;
    }

    &-Wrapper {
        background: rgba(22, 126, 191, 0.02);
        border: 1px solid rgba(22, 126, 191, 0.1);
        border-radius: 10px;
        grid-template-columns: minmax(120px, 1fr) 3fr 1fr 1fr;
        align-items: start;
        padding: 16px;
        gap: 20px;

        &_isCart {
            & .CartItem-Title {
                @include desktop {
                    padding-inline-start: 0;
                }
            }
        }

        &_isCheckout {
            grid-template-columns: 120px 1fr;
            padding: 12px 20px 12px 12px !important;

            & .CartItem-Title {
                width: 100%;
            }

            & .CartItem-ProductDetails {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                justify-content: space-between;
                gap: 15px;
            }

            & .CartItem-ProductActions {
                @include mobile {
                    row-gap: 15px;
                }
            }

            & .CartItem-Price {
                margin: 0;
            }

            & .CartItem-CartItemRows {
                justify-content: initial;
            }
        }

        &_isMobileLayout {
            grid-template-columns: 90px auto;
            padding: 16px;
            gap: 10px;
        }
    }

    &-ProductInfo {
        flex-direction: column;
    }

    &-Price {
        font-size: 24px;
        font-weight: 500;
        color: var(--secondary-blue-color);
        margin-block-start: 30px;

        &_isSingleProductPrice {
            text-align: start;
            margin-block-end: 22px;
            margin-block-start: 0;
        }

        @include mobile {
            margin-block-start: 0;
            font-size: 16px;
        }
    }

    &-Heading {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: var(--primary-blue-color);
        margin-block-end: 14px;
        white-space: normal;

        @include mobile {
            font-size: 14px;
            margin-block-end: 8px;
        }
    }

    &-Picture {
        width: 120px;
        height: 120px;

        &_isMobileLayout {
            width: 90px;
            height: 90px;
        }

        img {
            object-position: center;
        }
    }

    &-ProductActions {
        margin-block-start: 20px;

        &_isMobileLayout {
            flex-wrap: wrap;
            align-items: baseline;

            [type="button"] {
                width: 24px;
                height: 24px;
            }
        }

        @include mobile {
            margin-block-start: 0;
        }
    }

    &-Delete {
        margin-inline-start: 0;
        color: #6c6f7a;

        .BinIcon {
            width: 16px;
            height: 16px;
            fill: var(--primary-blue-color);
        }

        &:hover {
            .BinIcon {
                fill: var(--primary-blue-color);
            }

            color: var(--primary-blue-color);
            text-decoration: underline;
        }

        &_isMobileLayout {
            margin-inline-start: 3px;
            height: auto;
            padding-inline-start: 0;
            flex-basis: 100%;
        }
    }

    &-DeleteButtonText {
        font-size: 16px;

        &_isMobileLayout {
            margin-block-start: 3px;
            font-size: 12px;
            display: inherit;
        }

        @include tablet {
            display: inherit;
        }

        @include narrow-desktop {
            display: inherit;
        }
    }

    & &-Qty {
        display: inline-flex;
        // Stupid hack from Scandi no way to avoid
        @include desktop {
            border: 1px solid var(--primary-blue-color);
            border-radius: 5px;
            padding: 4px;
        }

        input {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            font-size: 16px;
            color: var(--secondary-blue-color);
            border-radius: 5px;
            margin: 0 2px;
            padding-block-start: 2px;

            @include desktop {
                width: 34px;
                height: 34px;
            }
        }

        [type="button"] {
            width: 24px;
            height: 24px;
            border: solid 1px #e7f2f9;
            border-radius: 5px;

            @include desktop {
                width: 32px;
                height: 34px;
            }

            &:hover {
                background-color: #ddf7fe;
            }

            .AddIcon,
            .MinusIcon {
                fill: var(--primary-blue-color);
                width: 16px;
                height: 16px;

                @include desktop {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        [type="number"] {
            pointer-events: auto;
            font-size: 16px;
        }
    }
}

.spinwin {
    border-radius: 10px;
    background: linear-gradient(90deg, #FBFCFE -19.22%, #FFFCFC 16.48%);
    background-blend-mode: multiply;
    border: 1px solid #8E5454;

    @include mobile {
        height: 102px;
        display: flex;
        align-items: center;
    }

    .Field-Wrapper_name_spin-win2_item_qty {
        display: none;
    }

    .CartItem-Delete {
        display: none;
    }

    .CartItem-Wrapper {
        align-items: center;
    }

    .CartItem-Picture {
        width: 120px;
        height: 80px;

        @include  mobile {
            width: 90px;
        }
    }

    .CartItem-Heading {
        margin-block-end: 0px;

        @include mobile {
            line-height: normal;
            width: 201px;
        }
    }

    .CartItem-CartItemRows {
        @include  mobile {
            justify-content: inherit;
        }
    }

    .CartItem-Wrapper_isMobileLayout {
        background: none;
        border: none;
        gap: 14px;
        display: flex;
    }

    .CartPage-Items {
        gap: 12px;
    }
}

.free-gift {
    width: 100%;
    justify-content: right;
    display: flex;
    margin: auto;

    @include mobile {
        justify-content: left;
    }
}

.free-gift-icon {
    border-radius: 8px;
    background: #D72828;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 120px;
    justify-content: center;
    margin-block-start: 3px;
    height: 30px;

    @include mobile {
        margin-block-start: 8px;
        width: 90px;
    }
}
