/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.CartPage {
    //margin-block-end: 180px;

    @include desktop {
        margin-block-end: 0px;
    }

    .CheckoutOrderSummary-ItemsCount {
        display: none;
    }

    &-TableHead {
        grid-template-columns: 1fr 2fr 1fr;
        background-color: inherit;
        border-top: 1px solid var(--primary-grey-color);
        border-bottom: 1px solid var(--primary-grey-color);
        color: #6c6f7a;
        font-size: 16px;
        font-weight: normal;
        padding: 14px 24px;

        span {
            text-align: end;
        }
    }

    &-Empty {
        margin-block-start: 60px;
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 25px;

        @include desktop {
            margin-block-end: 30px;
        }
    }

    &-Static {
        flex-grow: 7;
        flex-basis: 700px;

        .Message {
            margin: 20px 0 0px;

            @include mobile {
                margin: 0px 0 20px;
            }
        }

        hr {
            @include mobile {
                display: none;
            }
        }
    }

    &-Floating {
        flex-basis: 420px;
        flex-grow: 1;
        margin-block-start: 0;

        hr {
            border-top: 1px solid var(--primary-grey-color);
        }
    }

    &-Items {
        display: grid;
        gap: 20px;
        margin-block-start: 14px;

        @include mobile {
            margin-block-start: 0px;
        }
    }

    @include mobile {
        margin-block-start: 0;
    }
}

.SubscriptionModule {
    background: rgba(22, 126, 191, 0.02);
    border: 1px solid rgba(22, 126, 191, 0.1);
    border-radius: 10px;
    align-items: start;
    padding: 30px 30px 30px 10px;
}

.item-count {
    @include  desktop {
        padding-block-start: 24px;
    }

    @include  mobile {
        padding-block-end: 12px;
    }

    font-size: 24px;
    font-weight: 700;

    span {
        padding-inline-start: 10px;
        font-size: 16px;
        color: #6c6f7a;
    }
}

.empty-cart {
    border: none;

    @include  desktop {
        text-align: center;
    }

    img {
        width: 240px;
        height: auto;
    }

    .CartPage-CheckoutButton {
        width: 50%;
        cursor: pointer;

        @include  desktop {
            width: 16%;
        }
    }

    .text {
        font-size: 22px;
        line-height: 26px;
        color: var(--tilte--color);
        margin: 18px;
    }
}
